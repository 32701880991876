import React, { useState } from "react";
import "./App.css";

function launchCommandBar(UID) {
  window.CommandBar.boot({
    id: UID, // [required] A unique string to identify the current user
  });
}

function sayHi(args) {
  window.alert(`Hello ${args.name}`);
}

function App() {
  const [mode, setMode] = useState("#preview");

  const changeMode = (args) => {
    setMode(args.mode);
  };

  launchCommandBar("TEST_USER_1");

  window.CommandBar.addCallback("sayHi", sayHi);
  window.CommandBar.addCallback("changeMode", changeMode);
  window.CommandBar.addContext({
    mode: ["#preview", "#editor"],
  });

  return (
    <div className="App">
      <header className="App-header">
        <p>
          Edit <code>src/App.js</code> and save to reload.
        </p>
        <div>Mode: {mode}</div>
      </header>
    </div>
  );
}

export default App;
